import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { fetchAuthSession } from "aws-amplify/auth";
import qs from "qs";
class ApiService {
  constructor() {
    this.apiBaseURL =
      window._env_ &&
      window._env_.REACT_APP_BACKEND_API_BASE_URL.length > 0 &&
      window._env_.REACT_APP_BACKEND_API_BASE_URL !== "%%BACKEND_API_BASE_URL%%"
        ? window._env_.REACT_APP_BACKEND_API_BASE_URL
        : process.env.REACT_APP_BACKEND_API_BASE_URL;
    this.axiosInstance = axios.create();
  }

  /**
   * get all or filtered facilities.
   * keywords are optional
   */
  getFacilities = async (keywords) => {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString();

    const isLocalhost = window.location.hostname === "localhost";
    const url = isLocalhost
      ? `${this.apiBaseURL}/facility`
      : `https://${this.apiBaseURL}/facility`;
    try {
      const response = await axios.get(url, {
        headers: {
          requestid: uuidv4(),
          authorization: "Bearer " + idToken,
        },
        /*params: {
          keywords: keywords === undefined ? [] : keywords,
        },*/
      });

      return response;
    } catch (error) {
      console.error("Error fetching facilities:", error);
      throw error;
    }
  };

  /**
   * get facility details by facility id
   */
  getFacilityDetails = async (facilityId) => {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString();
    const encodedPathParam = encodeURIComponent(facilityId);
    const isLocalhost = window.location.hostname === "localhost";
    const url = isLocalhost
      ? `${this.apiBaseURL}/facility/${encodedPathParam}`
      : `https://${this.apiBaseURL}/facility/${encodedPathParam}`;

    try {
      const response = await axios.get(url, {
        headers: {
          requestid: uuidv4(),
          authorization: "Bearer " + idToken,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching facility details:", error);
      throw error;
    }
  };

  /**
   * create map from list of trace ids
   * returns a job id
   */
  createMap = async (facilityId, traceIds) => {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString();
    const isLocalhost = window.location.hostname === "localhost";
    const url = isLocalhost
      ? `${this.apiBaseURL}/map`
      : `https://${this.apiBaseURL}/map`;

    try {
      const response = await axios.post(
        url,
        { items: traceIds },
        {
          headers: {
            requestid: uuidv4(),
            authorization: "Bearer " + idToken,
          },
          params: { f: facilityId },
          paramsSerializer: (params) => qs.stringify(params, { encode: false }),
        }
      );
      return response;
    } catch (error) {
      console.error("Error creating new map:", error);
      throw error;
    }
  };

  /**
   * delete a trace by traceId as path param
   */
  deleteTrace = async (traceId) => {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString();
    const encodedPathParam = encodeURIComponent(traceId);
    const isLocalhost = window.location.hostname === "localhost";
    const url = isLocalhost
      ? `${this.apiBaseURL}/trace/${encodedPathParam}`
      : `https://${this.apiBaseURL}/trace/${encodedPathParam}`;

    try {
      const response = await axios.delete(url, {
        headers: {
          requestid: uuidv4(),
          authorization: "Bearer " + idToken,
        },
      });
      return response;
    } catch (error) {
      console.error(
        "Error deleting trace:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  /**
   * delete a map by map id
   */
  deleteMap = async (mapId) => {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString();
    const isLocalhost = window.location.hostname === "localhost";
    const url = isLocalhost
      ? `${this.apiBaseURL}/map/${mapId}`
      : `https://${this.apiBaseURL}/map/${mapId}`;

    try {
      const response = await axios.delete(url, {
        headers: {
          requestid: uuidv4(),
          authorization: "Bearer " + idToken,
        },
      });
      return response;
    } catch (error) {
      console.error(
        "Error deleting map:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  /**
   * get map
   * if map id not null then get the map by that id ,
   * if map id null then get latest map
   */
  getMapModel = async (facilityId, mapId) => {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString();
    let tempMapId = "";
    if (mapId !== null && mapId.length > 0) {
      tempMapId = mapId;
    } else {
      tempMapId = facilityId;
    }

    const isLocalhost = window.location.hostname === "localhost";
    const url = isLocalhost
      ? `${this.apiBaseURL}/map/${tempMapId}/obj`
      : `https://${this.apiBaseURL}/map/${tempMapId}/obj`;

    try {
      const response = await axios.get(url, {
        headers: {
          requestid: uuidv4(),
          authorization: "Bearer " + idToken,
        },
      });
      return response;
    } catch (error) {
      console.error(
        "Error fetching map model:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  /**
   *  Method to upload several trace files
   */
  uploadFiles = async (files, cancelToken) => {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString();
    const isLocalhost = window.location.hostname === "localhost";
    const url = isLocalhost
      ? `${this.apiBaseURL}/trace`
      : `https://${this.apiBaseURL}/trace`;

    try {
      const response = await axios.post(url, files, {
        headers: {
          "Content-Type": "application/json",
          requestid: uuidv4(),
          authorization: "Bearer " + idToken,
          // timestamp: new Date().toISOString(),
          Accept: "application/json",
        },
      });
      return response;
    } catch (error) {
      console.error(
        "Error uploading files:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  /**
   * get status of map creation
   * returns a string 'PENDING', 'RUNNING', 'COMPLETED', 'FAILED'
   */
  getMapJobStatus = async (jobId) => {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString();
    const isLocalhost = window.location.hostname === "localhost";
    const url = isLocalhost
      ? `${this.apiBaseURL}/job/${jobId}`
      : `https://${this.apiBaseURL}/job/${jobId}`;

    try {
      const response = await this.axiosInstance.get(
        // Use the initialized axiosInstance
        url,
        {
          headers: {
            requestid: uuidv4(),
            authorization: "Bearer " + idToken,
          },
        }
      );
      return response;
    } catch (error) {
      console.error(
        "Error fetching map job status:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };
}

const apiService = new ApiService();

export default apiService;
