// modules
import React from "react";
import "../styles/ProfilePage.scss";

const ProfilePage = () => {
  return (
    <div className="main-container">
      <h1 className="Greeting">Profile Page</h1>
    </div>
  );
};

export default ProfilePage;
