import React, { useState, useEffect } from "react";
import "../styles/MapsTable.scss";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import IconButton from "@mui/material/IconButton";
import CustomSnackbarAlert from "../components/CustomSnackbarAlert";
import ApiService from "../services/ApiService.js";

const MapsTable = ({ facilityDetails, getFacilityInfo }) => {
  const location = useLocation();
  const [mapsData, setMapsData] = useState(facilityDetails.Maps || []); 

  const navigate = useNavigate();
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [deleteConfirmationRow, setDeleteConfirmationRow] = useState(null);

  useEffect(() => {
    if (facilityDetails && facilityDetails.Maps) {
      setMapsData(facilityDetails.Maps);
    }
  }, [facilityDetails]);

  useEffect(() => {}, [mapsData]);

  function convertUnixTimestamp(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  /**
   * changes the delete icon of a specific row to the delete conf
   */
  const handleDeleteClick = (mapId) => {
    setDeleteConfirmationRow(mapId);
  };

  /**
   * changes the delete conf of a specific row to the delete icon when "NO" btn is clicked
   */
  const handleCancelDelete = () => {
    setDeleteConfirmationRow(null);
  };

  /**
   * when "YES" button is clicked , call api to delete the map by its ID
   * then reloads updated data.
   * also shows success/error msg in snackbar
   */
  const handleConfirmDelete = async (mapId) => {
    try {
      const res = await ApiService.deleteMap(mapId);
      if (res.status === 204) {
        setAlertMsg("Map has successfully been deleted !");
        setAlertSeverity("success");
        await getFacilityInfo(facilityDetails.General.facilityId); 
        setMapsData(facilityDetails.Maps); 
      } else {
        console.error(`Unexpected status code: ${res.status}`);
        setAlertMsg(`Unexpected status code: ${res.status}`);
        setAlertSeverity("error");
      }
    } catch (error) {
      setAlertMsg("Map deletion failed !");
      setAlertSeverity("error");
      console.error("Error deleting map", error);
    } finally {
      setDeleteConfirmationRow(null);
    }
  };

  /**
   * navigates to the 3D map page to display this specific map
   */
  const threeDBtnClick = (mapId) => {
    localStorage.setItem("prevPagePath", location.pathname);
    localStorage.setItem("wasFacilityDetailsOpen", true);
    localStorage.setItem(
      "lastSearchedFacilityName",
      facilityDetails.General.description
    );
    const facility3DPath = `/3d/${mapId}`;
    navigate(facility3DPath);
  };

  return (
    <TableContainer className="mtable-main-container" component={Paper}>
      <CustomSnackbarAlert
        severity={alertSeverity}
        duration={2500}
        message={alertMsg}
        onAlertClose={() => setAlertMsg("")}
      ></CustomSnackbarAlert>

      <Table
        stickyHeader
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Created</TableCell>
            <TableCell align="center">Size (kB)</TableCell>
            <TableCell align="center">Parking Slots</TableCell>
            <TableCell align="center">View</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mapsData &&
            mapsData.length > 0 &&
            mapsData.map((row) => (
              <TableRow
                key={row.mapId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">
                  {convertUnixTimestamp(row.creationDate)}
                </TableCell>
                <TableCell align="center">
                  {(row.size / 1000).toFixed(2)}
                </TableCell>
                <TableCell align="center">{row.slots}</TableCell>
                <TableCell align="center" component="th" scope="row">
                  <IconButton
                    className="mtable-3d-iconbtn"
                    onClick={() => {
                      threeDBtnClick(row.mapId);
                    }}
                    aria-label="3d"
                  >
                    <ViewInArIcon className="mtable-icon-3" />
                  </IconButton>
                </TableCell>

                <TableCell
                  sx={{ width: "120px" }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  {deleteConfirmationRow === row.mapId ? (
                    <div className="rtable-delete-confirmation-container">
                      <span>Delete map?</span>
                      <div className="rtable-button-container">
                        <button
                          className="rtable-button-yes"
                          onClick={() => handleConfirmDelete(row.mapId)}
                          size="small"
                        >
                          <span className="rtable-btn-text">Yes</span>
                        </button>
                        <button
                          className="rtable-button-no"
                          onClick={handleCancelDelete}
                          size="small"
                        >
                          <span className="rtable-btn-text">No</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <IconButton
                      className="mtable-x-iconbtn"
                      onClick={() => handleDeleteClick(row.mapId)}
                      aria-label="delete"
                    >
                      <ClearOutlinedIcon className="mtable-icon-2" />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MapsTable;
