// modules
import React from "react";
import "../styles/MapPage.scss";
import TwoDMap from "../components/TwoDMap.jsx";

const MapPage = () => {
  return (
    <div className="main-container">
      <TwoDMap />
    </div>
  );
};

export default MapPage;
