import React, { useEffect, useState } from "react";
import "../styles/FacilitiesFilters.scss";
import theme from "../styles/themes/facilitiesFiltersTheme";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomSnackbarAlert from "../components/CustomSnackbarAlert";

const FacilitiesFilters = ({ facilities, handleFilterChange }) => {
  const [nbFacilities, setNbFacilities] = useState(facilities.length);
  const [errorInput, setErrorInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputMsg, setInputMsg] = useState("");
  const [hasInvalidInput, setHasInvalidInput] = useState(false);
  const [keywordsArr, setKeywordsArr] = useState([]);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  useEffect(() => {
    setNbFacilities(facilities.length);
  }, [facilities]);

  useEffect(() => {
    let hasSpecialChars = containsSpecialCharacters(inputValue);
    if (hasSpecialChars === false) {
      if (inputValue.length >= 2) {
        setHasInvalidInput(false);
        setInputMsg("Press ENTER to add a new keyword");
        setErrorInput(false);
      } else {
        if (inputValue.length === 0) {
          setHasInvalidInput(false);
          setInputMsg("");
          setErrorInput(false);
        } else {
          setHasInvalidInput(true);
          setInputMsg("Please enter at least 2 characters");
          setErrorInput(true);
        }
      }
    }
    if (hasSpecialChars === true) {
      setHasInvalidInput(true);
      setInputMsg("Please remove any special characters");
      setErrorInput(true);
    }
  }, [inputValue]);

  useEffect(() => {
    const filterFacilities = () => {
      let filteredFacilities = facilities;

      keywordsArr.forEach((keyword) => {
        const lowerCaseKeyword = keyword.toLowerCase();
        if (lowerCaseKeyword === "l4") {
          filteredFacilities = filteredFacilities.filter(
            (facility) => facility.isLevel4Permitted
          );
        } else if (lowerCaseKeyword === "tmp") {
          filteredFacilities = filteredFacilities.filter(
            (facility) => !facility.isExternalMapFacility
          );
        } else if (lowerCaseKeyword === "emp" || lowerCaseKeyword === "csm") {
          filteredFacilities = filteredFacilities.filter(
            (facility) => facility.isExternalMapFacility
          );
        } else {
          filteredFacilities = filteredFacilities.filter(
            (facility) =>
              facility.description.toLowerCase().includes(lowerCaseKeyword) ||
              facility.facilityId.toLowerCase().includes(lowerCaseKeyword) ||
              facility.physicalAddress.toLowerCase().includes(lowerCaseKeyword)
          );
        }
      });

      return filteredFacilities;
    };

    const filteredData = filterFacilities();
    handleFilterChange(filteredData);
  }, [keywordsArr, handleFilterChange, facilities]);

  function containsSpecialCharacters(str) {
    const specialCharactersRegex = /[*/\\}{[\]()+=?$#&]/;
    return specialCharactersRegex.test(str);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (hasInvalidInput === false && inputValue !== "") {
        setKeywordsArr((prev) => [...prev, inputValue]);
        setInputValue("");
        setInputMsg("Press ENTER to add a new keyword");
      }
    }
  };

  const handleDeleteChip = (index) => {
    setKeywordsArr((prev) => prev.filter((_, i) => i !== index));
  };

  const handleInputChange = (event) => {
    let value = event.target.value;

    if (value.charAt(0) === " ") {
      value = value.trimStart();
    }

    if (value.length > 1 && value.slice(-2) === "  ") {
      value = value.slice(0, -1);
    }

    setInputValue(value);
  };

  return (
    <div className="ffilters-main-container">
      <CustomSnackbarAlert
        severity={alertSeverity}
        duration={2500}
        message={alertMsg}
        onAlertClose={() => setAlertMsg("")}
      ></CustomSnackbarAlert>
      <div className="ffilters-container-1">
        <ThemeProvider theme={theme}>
          <FormControl sx={{ m: 1, width: "500px" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
            <OutlinedInput
              value={inputValue}
              onChange={handleInputChange}
              error={errorInput}
              onKeyDown={handleKeyDown}
              id="outlined-adornment-search"
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" disabled={hasInvalidInput}>
                    <SavedSearchIcon
                      className={
                        hasInvalidInput
                          ? "search-icon-disabled"
                          : "search-icon-default"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              }
              label="Search"
            />
            <FormHelperText
              className={
                hasInvalidInput ? "input-msg-red" : "input-msg-default"
              }
            >
              {inputMsg}
            </FormHelperText>
          </FormControl>
        </ThemeProvider>
        <div className="ffilters-text-1 mt1">{nbFacilities} Facilities</div>
      </div>

      <Stack className="ff-chip-main-container" direction="row" spacing={1}>
        {keywordsArr.map((keyword, index) => (
          <Chip
            className="ff-chip-container"
            key={index}
            label={keyword}
            onDelete={() => handleDeleteChip(index)}
            deleteIcon={<CloseIcon className="ff-chip-delete-icon" />}
          />
        ))}
      </Stack>
    </div>
  );
};

export default FacilitiesFilters;
