import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "../styles/TwoDMap.scss";
import retinaIcon from "../static/images/marker-icon-2x.png";
import iconShadow from "../static/images/marker-shadow.png";
import FacilityBasicCard from "./FacilityBasicCard.jsx";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import theme from "../styles/themes/twoDMapFilterTheme.js";
import { ThemeProvider } from "@mui/material/styles";
import ApiService from "../services/ApiService.js";
import CustomSnackbarAlert from "../components/CustomSnackbarAlert";

// Fix for default marker icon issues
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: retinaIcon,
  iconUrl: retinaIcon,
  shadowUrl: iconShadow,
  iconSize: [30, 49],
  iconAnchor: [15, 49],
  shadowSize: [49, 49],
  shadowAnchor: [15, 49],
});

const MapView = ({ searchedFacility }) => {
  const map = useMap();
  const hasRunEffectRef = useRef(false);

  useEffect(() => {
    if (
      searchedFacility &&
      searchedFacility.centerPosition &&
      !hasRunEffectRef.current
    ) {
      hasRunEffectRef.current = true;
      map.setView(searchedFacility.centerPosition, 14);

      setTimeout(() => {
        // click in the center of the screen
        const event = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
          clientX: window.innerWidth / 2,
          clientY: window.innerHeight / 2,
        });
        document
          .elementFromPoint(event.clientX, event.clientY)
          .dispatchEvent(event);
      }, 500);

      // Reset the ref after the timeout
      setTimeout(() => {
        hasRunEffectRef.current = false;
      }, 1000);
    }
  }, [searchedFacility, map]);

  return null;
};

const TwoDMap = () => {
  const [searchedFacility, setSearchedFacility] = useState(null);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [facilities, setFacilities] = useState([]);
  const mapRef = useRef();
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  /**
   * upon load , fetch facilities list from api
   */
  useEffect(() => {
    const getFacilities = async () => {
      try {
        const res = await ApiService.getFacilities([]);
        if (res.status === 200) {
          setFacilities(res.data);
          if (res.data.length > 0 && mapRef.current) {
            const bounds = res.data.map((facility) => facility.centerPosition);
            mapRef.current.fitBounds(bounds);
          }
        } else {
          console.error(`Unexpected status code: ${res.status}`);
          setAlertMsg(`Unexpected status code: ${res.status}`);
          setAlertSeverity("error");
        }
      } catch (error) {
        console.error("Error fetching facilities:", error);
      }
    };

    getFacilities();
  }, []);

  /**
   * when facilities val changes,
   */
  useEffect(() => {
    if (facilities.length > 0 && mapRef.current) {
      const bounds = facilities.map((facility) => facility.centerPosition);
      mapRef.current.fitBounds(bounds);
    }
  }, [facilities]);

  useEffect(() => {
    if (facilities.length > 0) {
      const storedFacilityId = localStorage.getItem("lastCheckedFacility");
      if (storedFacilityId) {
        const foundFacility = facilities.find(
          (facility) => facility.facilityId.toString() === storedFacilityId
        );
        if (foundFacility) {
          setValue(foundFacility);
          setSearchedFacility(foundFacility);
        }
      }
    }
  }, [facilities]);

  return (
    <MapContainer
      className="main-map-cont"
      //center={[48.866669, 2.33333]} // Default center if no facilities are available
      zoom={14}
      ref={mapRef}
    >
      <CustomSnackbarAlert
        severity={alertSeverity}
        duration={2500}
        message={alertMsg}
        onAlertClose={() => setAlertMsg("")}
      ></CustomSnackbarAlert>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {facilities.length > 0 &&
        facilities.map((facilityObject) => (
          <Marker
            key={facilityObject.facilityId}
            position={facilityObject.centerPosition}
          >
            <Popup>
              <FacilityBasicCard facilityObject={facilityObject} />
            </Popup>
          </Marker>
        ))}

      <ThemeProvider theme={theme}>
        <Autocomplete
          isOptionEqualToValue={(option, value) =>
            option.facilityId === value.facilityId
          }
          className="two-d-map-search-bar"
          value={value}
          onChange={(event, object) => {
            setValue(object);
            setSearchedFacility(object);
          }}
          inputValue={inputValue}
          onInputChange={(event, label) => {
            setInputValue(label);
          }}
          id="controllable-states-demo"
          options={facilities}
          getOptionLabel={(option) => option.description || ""}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Search" />}
        />
      </ThemeProvider>
      {searchedFacility && <MapView searchedFacility={searchedFacility} />}
    </MapContainer>
  );
};

export default TwoDMap;
