// modules
import React, { useEffect } from "react";
import "../styles/HelpPage.scss";

const HelpPage = () => {
  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="Container">
    </div>
  );
};

export default HelpPage;
