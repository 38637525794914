import React, { useState, useEffect } from "react";
import "../styles/FacilityBasicCard.scss";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import FacilityDetailsCard from "./FacilityDetailsCard";

const FacilityBasicCard = ({ facilityObject }) => {
  const location = useLocation();
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const handleOpenDetailsModal = () => {
    setOpenDetailsModal(true);
  };
  const handleCloseDetailsModal = () => {
    localStorage.removeItem("wasFacilityDetailsOpen");
    setOpenDetailsModal(false);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const a = localStorage.getItem("wasFacilityDetailsOpen");
    const b = localStorage.getItem("lastCheckedFacility");
    (a === true || a === "true") && b === facilityObject.facilityId
      ? setOpenDetailsModal(true)
      : setOpenDetailsModal(false);
  }, [facilityObject]);

  const threeDBtnClick = () => {
    localStorage.setItem("prevPagePath", location.pathname);
    localStorage.setItem("wasFacilityDetailsOpen", false);
    localStorage.setItem(
      "lastSearchedFacilityName",
      facilityObject.description
    );
    const facility3DPath = `/3d/${facilityObject.facilityId}/latest`;
    navigate(facility3DPath);
  };

  return (
    <div className="map-popup-card-main">
      <div>
        <div className="map-popup-card-chip-container">
          {facilityObject.isLevel4Permitted && (
            <Chip
              variant="outlined"
              className="chip-style chip-first chip-l4"
              color="red"
              label="L 4"
              size="small"
              sx={{
                color: "rgb(90, 162, 221) ",
                borderColor: "rgb(90, 162, 221) ",
                "& .MuiChip-label": {
                  color: "rgb(90, 162, 221) ",
                },
              }}
            />
          )}
          {facilityObject.isExternalMapFacility && (
            <Chip
              variant="outlined"
              className="chip-style chip-first chip-csm"
              label="CSM"
              size="small"
              sx={{
                color: "rgb(208, 224, 112)",
                borderColor: "rgb(208, 224, 112)",
                "& .MuiChip-label": {
                  color: "rgb(208, 224, 112)",
                },
              }}
            />
          )}
          {facilityObject.isExternalMapFacility === false && (
            <Chip
              variant="outlined"
              className="chip-style chip-first chip-tmp"
              label="TMP"
              size="small"
              sx={{
                color: "rgb(221, 90, 90)",
                borderColor: "rgb(221, 90, 90)",
                "& .MuiChip-label": {
                  color: "rgb(221, 90, 90)",
                },
              }}
            />
          )}
        </div>

        <div className="popup-card-nameaddress-container">
          <span className="map-popup-card-title">
            {facilityObject.description}
          </span>
          <span className="map-popup-card-subtitle">
            {facilityObject.physicalAddress}
          </span>
          <span className="map-popup-card-subtitle">
            Location : {facilityObject.facilityId}
          </span>
        </div>
      </div>

      {facilityObject.isExternalMapFacility !== false && (
        <div className="map-popup-card-buttons">
          <Button
            onClick={threeDBtnClick}
            size="small"
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "var(--purple)",
              height: "26px",
              width: "45%",
              borderRadius: "4px",
              position: "relative",
              bottom: "6px",
            }}
            disableElevation
          >
            3D View
          </Button>
          <Button
            variant="outlined"
            onClick={handleOpenDetailsModal}
            className="map-popup-card-button-details"
            size="small"
            sx={{
              textTransform: "none",
              backgroundColor: "var(--bluegrey)",
              color: "white",
              border: "1px solid var(--purple)",
              borderRadius: "4px",
              height: "26px",
              width: "45%",
              position: "relative",
              bottom: "6px",
            }}
            disableElevation
          >
            Details
          </Button>
        </div>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDetailsModal}
        onClose={handleCloseDetailsModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDetailsModal}>
          <Box className="detailsModal">
            <FacilityDetailsCard facilityId={facilityObject.facilityId} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default FacilityBasicCard;
