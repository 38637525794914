import React, { useState, useEffect, useRef } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { Center, OrbitControls, Environment } from "@react-three/drei";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { Box3, Vector3 } from "three";
import IconButton from "@mui/material/IconButton";
import "../styles/MapModelViewer.scss";
import ApiService from "../services/ApiService.js";
import CustomSnackbarAlert from "../components/CustomSnackbarAlert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
const MapModelViewer = ({ mapId, facilityId }) => {
  const [mapBlobURL, setMapBlobURL] = useState();
  const [map, setMap] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [mapTitle, setMapTitle] = useState("");
  const [backBtnTitle, setBackBtnTitle] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const navigate = useNavigate();

  useEffect(() => {}, [map]);
  useEffect(() => {
    const a = localStorage.getItem("lastCheckedFacility");
    const b = localStorage.getItem("wasFacilityDetailsOpen");
    const c = localStorage.getItem("prevPagePath");
    const d = localStorage.getItem("lastSearchedFacilityName");
    setMapTitle(d);
    if (c && c === "/map") {
      setBackBtnTitle("Map");
    }
    if (c && c === "/facilities") {
      setBackBtnTitle("Facilities");
    }
  }, []);

  useEffect(() => {
    if (facilityId) {
      if (mapId) {
        const extractedFacilityId = mapId.split(":")[0];
        localStorage.setItem("lastCheckedFacility", extractedFacilityId);
        fetchMap(facilityId, mapId);
        getMapCreationDate(facilityId, mapId);
      } else {
        localStorage.setItem("lastCheckedFacility", facilityId);
        fetchMap(facilityId, null);
      }
    }
  }, [mapId, facilityId]);

  const fetchMap = async (facilityId, mapId) => {
    try {
      const res = await ApiService.getMapModel(facilityId, mapId);
      if (res.status === 200) {
        setMap(res.data);
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        setMapBlobURL(url);
      } else if (res.status === 404) {
        setAlertMsg(`This facility does NOT have any maps yet!`);
        setAlertSeverity("error");
      } else {
        setAlertMsg(`Unexpected status code: ${res.status}`);
        setAlertSeverity("error");
      }
    } catch (error) {
      if (error.response.status === 404) {
        setAlertMsg(`This facility does NOT have any maps yet!`);
        setAlertSeverity("error");
      } else {
        setAlertMsg("Map could not be loaded!");
        setAlertSeverity("error");
      }
    }
  };

  // walkarround to get the map creation date
  const getMapCreationDate = async (facilityId, mapId) => {
    try {
      const res = await ApiService.getFacilityDetails(facilityId);
      if (res.status === 200) {
        const map = res.data.Maps.find((item) => item.mapId === mapId);
        if (map) {
          // If found, log or return the creationDate
          const parsedCreationDate = convertUnixTimestamp(map.creationDate);
          setCreationDate(parsedCreationDate);
        } else {
          console.error("Map with the specified mapId not found");
          setAlertMsg("Map not found");
          setAlertSeverity("error");
        }
      } else {
        console.error(`Unexpected status code: ${res.status}`);
        setAlertMsg(`Unexpected status code: ${res.status}`);
        setAlertSeverity("error");
      }
    } catch (error) {
      setAlertMsg("Unable to fetch map creation date !");
      setAlertSeverity("error");
      console.error("Unable to fetch map creation date", error);
    } finally {
    }
  };

  function convertUnixTimestamp(unixTimestamp) {
    // Create a new Date object using the Unix timestamp (in milliseconds)
    const date = new Date(unixTimestamp * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  function Model(props) {
    const obj = useLoader(OBJLoader, mapBlobURL);
    const ref = useRef();

    useEffect(() => {
      if (obj) {
        const box = new Box3().setFromObject(obj);
        const center = box.getCenter(new Vector3());
        obj.position.set(-center.x, -center.y, -center.z);
      }
    }, [obj]);

    return <primitive object={obj} ref={ref} {...props} />;
  }

  return (
    <div className="dexx">
      <CustomSnackbarAlert
        severity={alertSeverity}
        duration={2500}
        message={alertMsg}
        onAlertClose={() => setAlertMsg("")}
      ></CustomSnackbarAlert>

      <div>
        <span className="mapTitle">{mapTitle}</span>
        {mapId !== null && <span className="mapSubTitle">{creationDate}</span>}
        {mapId === null && <span className="mapSubTitle">Latest</span>}
      </div>
      <IconButton
        className="backBtn"
        onClick={() => {
          const c = localStorage.getItem("prevPagePath");
          navigate(c);
        }}
        aria-label="back"
      >
        <ArrowBackIosNewIcon className="backBtnIcon" />
        <span className="backBtnLabel">{backBtnTitle}</span>
      </IconButton>

      <Canvas
        shadows
        camera={{ fov: 35, position: [80, 80, 0] }}
        style={{ width: "100vw", height: "calc(100vh - 70px)" }}
      >
        <color attach="background" args={["#3a4764"]} />
        <group>
          <ambientLight intensity={1.8} />
          <Center position={[0, -3.5, 0]}>
            {mapBlobURL && (
              <Model
                scale={0.8}
                rotation={[(-90 * Math.PI) / 180, 0, (90 * Math.PI) / 180]}
              />
            )}
          </Center>
          <directionalLight intensity={0.8} position={[-20, 10, 70]} />
          <directionalLight intensity={2.5} position={[10, 10, -20]} />
        </group>
        <OrbitControls minPolarAngle={0} maxPolarAngle={Math.PI / 2} />
        <Environment preset="city" />
      </Canvas>
    </div>
  );
};

export default React.memo(MapModelViewer);
