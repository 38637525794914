import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import { useAuthenticator } from "@aws-amplify/ui-react";
import "../styles/Header.scss";
//import CustomAnimatedVLogo from '../components/CustomAnimatedVLogo';
import { fetchUserAttributes } from "aws-amplify/auth";

const Header = () => {
  const [email, setEmail] = useState("");
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();
  const location = useLocation();

  const pages = [
    { name: "Map", path: "/map" },
    { name: "Facilities", path: "/facilities" },
    { name: "Help", path: "/help" },
  ];

  const settings = [
    { name: "Upload", path: "/upload" },
    { name: "Logout", path: "/login" },
  ];

  useEffect(() => {
    // Create an AbortController instance
    const abortController = new AbortController();
    const fetchUserData = async () => {
      try {
        const attribs = await fetchUserAttributes();
        if (attribs && attribs.email) {
          setEmail(attribs.email);
        }
      } catch (error) {
        console.error("Error fetching user email:", error);
      }
    };

    if (authStatus === "authenticated") {
      fetchUserData();
    }

    // Cleanup function to abort the fetch if the component unmounts
    return () => {
      abortController.abort();
    };
  }, [authStatus]);

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserMenuItemClick = (event) => {
    const settingName = event.currentTarget.getAttribute("data-setting");
    const setting = settings.find((s) => s.name === settingName);
    setAnchorElUser(null);

    if (setting.name === "Logout") {
      signOut();
    } else {
      navigate(setting.path);
    }
  };

  return (
    <div className="header-container">
      {authStatus === "authenticated" && (
        <AppBar className="appbar" position="static">
          <Container maxWidth="xxl">
            <Toolbar
              disableGutters
              className="toolb"
              sx={{ position: "relative" }}
            >
              <div />

              {location.pathname !== "/upload" && (
                <>
                  <Box
                    className="header-appbarMenu"
                    sx={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {pages.map((page) => (
                      <Button
                        key={page.name}
                        onClick={() => {
                          localStorage.removeItem("prevPagePath");
                          localStorage.removeItem("wasFacilityDetailsOpen");
                          localStorage.removeItem("lastSearchedFacilityName");
                          localStorage.removeItem("lastCheckedFacility");
                          navigate(page.path)}}
                        sx={{
                          mr: "20px",
                          my: 2,
                          color: "white",
                          display: "block",
                          borderBottom:
                            location.pathname === page.path
                              ? "2px solid #8C8DF9"
                              : "none",
                        }}
                      >
                        {page.name}
                      </Button>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "-6px",
                      zIndex: 99,
                      flexGrow: 0,
                    }}
                  >
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ ml: 1 }}>
                        <ManageAccountsRoundedIcon className="accnt-icn" />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "35px", ml: "10px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem className="appbarMenuItem" disabled>
                        <Typography textAlign="center">{email}</Typography>
                      </MenuItem>
                      <Divider />
                      {settings.map((setting) => (
                        <MenuItem
                          key={setting.name}
                          data-setting={setting.name}
                          onClick={handleUserMenuItemClick}
                        >
                          <Typography textAlign="center">
                            {setting.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </div>
  );
};

export default Header;
