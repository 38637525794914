import React, { useEffect } from "react";
import "../styles/ThreeDPage.scss";
import MapModelViewer from "../components/MapModelViewer";
import { useParams } from "react-router-dom";

const ThreeDPage = () => {
  const { mapId, facilityId } = useParams();

  useEffect(() => {}, []);

  useEffect(() => {}, [mapId, facilityId]);

  const extractFacilityIdBeforeColon = (inputString) => {
    const parts = inputString.split(":");
    return parts.length > 1 ? parts[0] : "";
  };

  return (
    <div className="main-container">
      <MapModelViewer
        mapId={mapId === undefined ? null : mapId}
        facilityId={
          facilityId === undefined
            ? extractFacilityIdBeforeColon(mapId)
            : facilityId
        }
      />
    </div>
  );
};

export default ThreeDPage;
